
import React from "react"
import Layout from "../components/layout"

import {Container} from 'reactstrap'

export default ({ data }) => {
    return (
        <Layout title="404">
            <Container>
                <h1>NOT FOUND</h1>
            </Container>
        </Layout>
    )
}

